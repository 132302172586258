import { RudderAnalytics } from '@rudderstack/analytics-js';

import config from '../config';

const rudderanalytics = new RudderAnalytics();
if (config.rudderstack.writeKey) {
  rudderanalytics.load(config.rudderstack.writeKey, config.rudderstack.dataPlaneUrl, {
    useServerSideCookies: true,
    storage: {
      cookie: {
        domain: config.rudderstack.cookieDomain,
        secure: true,
        samesite: 'None',
      },
    },
  });
}

enum RudderEvent {
  AccountExists = 'Account Exists',
  AccordionView = 'Accordion View',
  AutoCheckLowMilage = 'AutoCheck Low Milage',
  DocumentsImagesUploadedFromDevice = 'Documents Images Uploaded From Device',
  DocumentsImagesTextedIn = 'Documents Images Texted In',
  DocumentsImagesTextedInReceived = 'Documents Images Texted In Received',
  DoubleTaxModalShown = 'Double Tax Modal Shown (names must match modal)',
  Error = 'Error',
  FlowEntry = 'Flow Entry',
  FinishedDocumentsPage = 'Finished Documents Page',
  LicensePlateProvided = 'License Plate Provided',
  OtherLienholderModalOpen = 'Other Lienholder Modal Open',
  OtherLienholderSelected = 'Other Lienholder Selected',
  PackageDetailsModalOpen = 'Package Details Modal Open',
  PaymentCalculatorModalOpen = 'Payment Calculator Modal Open',
  PayoffAutomaticallyRetrieved = 'Payoff Automatically Retrieved',
  PayoffAutomaticallyRetrieveFailure = 'Payoff Automatically Retrieve Failure',
  PayoffIncludesSalesTax = 'Payoff Includes Sales Tax',
  RStackLandingTest = 'Rstack - Landing Test',
  VideoReviewCompleted = 'Video Review Completed',
  VideoReviewStarted = 'Video Review Started',
  VinProvided = 'Vin Provided',
  VIPConsultation = 'VIP Consultation',

  // Signing Events
  SigningExperienceOpened = 'Signing Experience Opened',
  StartedSigning = 'Started Signing',
  FinishedSigning = 'Finished Signing',
  LabTest = 'LabTest',
  SanityMonitor = 'SanityMonitor',
  Promo = 'Promo',
  LabTestSuccess = 'LabTestSuccess',
}

export { rudderanalytics, RudderEvent };
