/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/jsx-pascal-case */

/* eslint-disable no-unused-vars */

/* eslint-disable react/no-array-index-key */

/* eslint-disable prettier/prettier */
import { Box, Button, Container, Flex, Link, Text } from '@chakra-ui/react';
import { createBrowserHistory } from 'history';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import LicensePlateOrVinInput_Learn from '../../components/LicensePlateOrVinInput_Learn';
import Title from '../../components/Title';
import { BLOG_SANITY } from '../../constants/urls';
import { BlogSearchTerm } from '../../helpers/Atom';
import Loading from '../Loading';
import BlogImageComponent from '../Sanity/Blog/BlogImageComponent';
import { featuredPostFetch } from '../Sanity/Data';
import { BlogPostProps } from '../Sanity/Model';
import Author from './Author';
import Nav from './Nav';
import Search from './Search';

const BlogWidget = () => {
  return (
    <>
      <Box
        borderBottomWidth={{
          base: 1,
          md: 0,
        }}
        pb={{
          base: 4,
          md: 0,
        }}
        mb={{
          base: 4,
          md: 0,
        }}
      >
        <LicensePlateOrVinInput_Learn />
      </Box>
    </>
  );
};

const Blog = () => {
  const history = createBrowserHistory({ forceRefresh: true });
  const [loading, setLoading] = useState<boolean>(false);
  const [featured, setFeatured] = useState<BlogPostProps[] | null>(null);
  const [blogSearchTerm] = useAtom(BlogSearchTerm);

  const fetch = async () => {
    setLoading(true);
    const data: BlogPostProps[] | null = await featuredPostFetch();
    setLoading(false);
    setFeatured(data);
  };

  const jump = (slug: string, isNav?: boolean) => {
    if (isNav) {
      history.push(`${BLOG_SANITY}/tag/${slug}`);
      return;
    }
    history.push(`${BLOG_SANITY}/${slug}`);
  };

  const tracking = () => {
    return null;
  };
  useEffect(() => {
    tracking();
  });

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <Container color="leaseEndBlue" maxW="container.xl" textAlign="center" p={0} mt={8}>
        <Nav />
        {loading ? (
          <Loading />
        ) : blogSearchTerm ? (
          <Search />
        ) : featured ? (
          <>
            <Flex
              flexDir={{ base: 'column', md: 'row' }}
              w="100%"
              justifyContent="flex-start"
              alignItems="flex-start"
              gap={{
                base: 8,
                md: 16,
              }}
              mt={5}
              mb={10}
            >
              <Flex
                w={{
                  base: '100%',
                  md: featured.length === 1 ? '100%' : '66.67%',
                }}
                flexDir="column"
                borderBottomWidth={{
                  base: 1,
                  md: 0,
                }}
                borderColor="#ddd"
                pb={{
                  base: 8,
                  md: 0,
                }}
              >
                <Text
                  className="le-blog-callout"
                  textAlign="left"
                  color="white"
                  fontWeight={600}
                  fontSize={16}
                  mb={3}
                  py={1}
                >
                  FEATURED ARTICLE
                </Text>
                {featured[0].mainImage && (
                  <BlogImageComponent value={featured[0].mainImage} isInline={false} isFeatured />
                )}
                <Title
                  fontWeight={700}
                  textAlign="left"
                  m={0}
                  my={5}
                  fontSize={{
                    base: '26pt',
                  }}
                >
                  <Link
                    title={featured[0].title}
                    href={`${BLOG_SANITY}/${featured[0].slug.current}`}
                    color="inherit"
                    _hover={{
                      color: 'inherit',
                    }}
                  >
                    {featured[0].title}
                  </Link>
                </Title>
                <Author post={featured[0]} showDate />
                {featured[0].snippet && (
                  <Text textAlign="left" fontSize="13pt" fontWeight={500} color="black">
                    {featured[0].snippet}
                  </Text>
                )}
                <Flex
                  mt={5}
                  w={{
                    base: '100%',
                    md: 'auto',
                  }}
                >
                  <Button
                    w={{
                      base: '100%',
                      md: 'auto',
                    }}
                    onClick={() => jump(featured[0].slug.current)}
                  >
                    <Link
                      title={featured[0].title}
                      href={`${BLOG_SANITY}/${featured[0].slug.current}`}
                      color="inherit"
                      _hover={{
                        color: 'inherit',
                      }}
                    >
                      READ FEATURED ARTICLE
                    </Link>
                  </Button>
                </Flex>
              </Flex>
              {featured.length > 1 && (
                <Flex
                  w={{
                    base: '100%',
                    md: '33.33%',
                  }}
                  flexDir="column"
                  gap={{
                    base: 5,
                    md: 5,
                  }}
                >
                  <BlogWidget />
                  <Flex flexDir="column" justifyContent="flex-start">
                    {featured[1].mainImage && (
                      <Box mb={3}>
                        <BlogImageComponent value={featured[1].mainImage} isInline={false} />
                      </Box>
                    )}
                    <Author post={featured[1]} showDate />
                    <Title
                      onClick={() => jump(featured[1].slug.current, false)}
                      cursor="pointer"
                      fontWeight={700}
                      textAlign="left"
                      m={0}
                      fontSize={{
                        base: '14pt',
                      }}
                    >
                      <Link
                        title={featured[1].title}
                        href={`${BLOG_SANITY}/${featured[1].slug.current}`}
                        color="inherit"
                        _hover={{
                          color: 'inherit',
                        }}
                      >
                        {featured[1].title}
                      </Link>
                    </Title>
                    <Flex mt={2}>
                      <Link
                        title={featured[1].title}
                        href={`${BLOG_SANITY}/${featured[1].slug.current}`}
                        fontSize="13pt"
                      >
                        Read More
                      </Link>
                    </Flex>
                  </Flex>
                  {featured[2] && (
                    <Flex flexDir="column">
                      {featured[2].mainImage && (
                        <Box mb={3}>
                          <BlogImageComponent value={featured[2].mainImage} isInline={false} />
                        </Box>
                      )}
                      <Author post={featured[2]} showDate />
                      <Title
                        cursor="pointer"
                        onClick={() => jump(featured[2].slug.current, false)}
                        fontWeight={700}
                        textAlign="left"
                        m={0}
                        fontSize={{
                          base: '14pt',
                        }}
                      >
                        <Link
                          title={featured[2].title}
                          href={`${BLOG_SANITY}/${featured[2].slug.current}`}
                          color="inherit"
                          _hover={{
                            color: 'inherit',
                          }}
                        >
                          {featured[2].title}
                        </Link>
                      </Title>
                      <Flex mt={2}>
                        <Link
                          title={featured[2].title}
                          href={`${BLOG_SANITY}/${featured[2].slug.current}`}
                          fontSize="13pt"
                        >
                          Read More
                        </Link>
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>
          </>
        ) : null}
      </Container>
    </>
  );
};

export default Blog;
