/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { useEffect, useState } from 'react';

import { convertToLeaseEndTimeZone } from '../utils/dates';
import { LE_DOW_OFF, LE_HOUR_END, LE_HOUR_START } from '../utils/globals';

const useLeaseEndHours = () => {
  const [closed, setClosed] = useState<boolean>(false);
  useEffect(() => {
    const dFull = convertToLeaseEndTimeZone(moment(new Date()).format('YYYY-MM-DD HH:mm'));
    const dStart = convertToLeaseEndTimeZone(
      moment(new Date()).format(`YYYY-MM-DD ${LE_HOUR_START}:00a`),
    );
    const dEnd = convertToLeaseEndTimeZone(moment(new Date()).format(`YYYY-MM-DD ${LE_HOUR_END}`));
    const afterHours: boolean = moment(dEnd).diff(moment(dFull), 'minutes') <= 0;
    const beforeHours: boolean = moment(dStart).diff(moment(dFull), 'minutes') > 0;
    const dayClosed: boolean = LE_DOW_OFF.includes(moment(dEnd).format('ddd').toLocaleUpperCase());
    setClosed(!!(afterHours || beforeHours || dayClosed));
  }, []);
  return closed;
};

export default useLeaseEndHours;
