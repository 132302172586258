/* eslint-disable no-empty */
import { useLazyQuery } from '@apollo/client';
import { Image } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { PrequalSearch } from '../../assets/Images';
import { VIPPhoneDealSetterDocument } from '../../gql/vipPhoneGql';
import { VIPDealId, VIPSetterProfile } from '../../helpers/Atom';
import { salesFetchByName } from '../Sanity/Data';
import { SalesProps } from '../Sanity/Model';

type Timer = ReturnType<typeof setInterval>;

const Setter = () => {
  const [setterCheck, setSetterCheck] = useState<boolean>(false);
  const [, setTimeElapsed] = useState(0);
  const [vipDealId] = useAtom(VIPDealId);
  const [getSetter] = useLazyQuery(VIPPhoneDealSetterDocument, {
    fetchPolicy: 'no-cache',
  });
  const [setter, setSetter] = useState<string | null>(null);
  const [, setSetterProfile] = useAtom(VIPSetterProfile);
  const handleSetter = async (dealId: number | null) => {
    if (!dealId) {
      return null;
    }
    await getSetter({
      variables: {
        dealId,
      },
    }).then((response) => {
      if (!response.data.dealSetter.setter) {
        setSetter(null);
        setSetterCheck(true);
        return;
      }
      setSetter(response.data.dealSetter.setter.name);
      setSetterCheck(false);
    });
    return null;
  };
  const sanitySetter = async () => {
    if (setter) {
      try {
        const data: SalesProps | null = await salesFetchByName(setter);
        setSetterProfile(data);
      } catch (error) {}
    }
  };
  useEffect(() => {
    const intervalId: string | number | undefined | Timer = setInterval(() => {
      if (setterCheck) {
        handleSetter(vipDealId);
      }
      setTimeElapsed((prevTime) => prevTime + 1);
    }, 5000); // Update every 100 seconds
    return () => clearInterval(intervalId); // Cleanup on unmount or when setterCheck changes
  }, [setterCheck]);
  useEffect(() => {
    sanitySetter();
  }, [setter]);
  useEffect(() => {
    setSetterCheck(true);
  }, []);
  return <Image src={PrequalSearch} width="345px" h="207px" />;
};

export default Setter;
