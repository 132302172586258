import moment from 'moment';

import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';

interface apiDurationProps {
  startTs: number;
  endpoint: string;
  statusCode: number;
  resultLength?: number | null;
}
export const apiResult = ({
  startTs = 0,
  endpoint = '',
  statusCode = 200,
  resultLength = null,
}: apiDurationProps) => {
  const endTs = moment(new Date()).unix();
  rudderanalytics.track(RudderEvent.SanityMonitor, {
    name: 'apiResult',
    endpoint,
    statusCode,
    startTs,
    endTs,
    resultLength: resultLength || 0,
  });
};
