/* eslint-disable func-names */

/* eslint-disable security/detect-non-literal-fs-filename */
import { Button, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
// eslint-disable-next-line no-restricted-imports
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { statesArray } from '../constants/states';
import {
  ACCOUNT_LOGIN,
  FLOW_ENTRY,
  KWLP_LEASE_BUYOUT,
  KWLP_LEASE_BUYOUT_RATES,
  KWLP_LEASE_BUYOUT_SELL,
} from '../constants/urls';
import { CookieKeys, useCookie } from '../hooks/useCookie';
import { RudderEvent, rudderanalytics } from '../utils/rudderstack';
import {
  DEFAULT_YEAR_DIFF,
  LICENSE_PLATE_NAME,
  LicensePlateOrVinInputProps,
  PlateVinFormFields,
  VIN_NAME,
  createQueryParams,
  getVSchema,
  handleOnSwitchChange,
} from './LicensePlateInputs/utils';
import Subtitle from './Subtitle';
import Switch from './Switch';
import Input from './formComponents/Input';
import Select from './formComponents/Select';
import { Link } from './ui/links/Link';

const LicensePlateOrVinInput = ({
  autoFocus = false,
  shoppingCart,
  zip,
}: LicensePlateOrVinInputProps) => {
  const [savedQueryParams] = useCookie<string>(CookieKeys.QUERY_PARAMS);
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const isAuthenticated = !!accessToken;
  const { search, pathname } = useLocation();
  const [currentInput, setCurrentInput] = useState(LICENSE_PLATE_NAME);
  const [dealExists, setDealExists] = useState(false);
  const [showOldCarWarning, setShowOldCarWarning] = useState(false);
  const isLicensePlate = LICENSE_PLATE_NAME === currentInput;

  const handleWarningsReset = () => {
    setDealExists(false);
    setShowOldCarWarning(false);
  };

  const handleSubmit = async (values: PlateVinFormFields) => {
    const urlParams = new URLSearchParams(search || savedQueryParams || '');
    const queryParams = createQueryParams(values, shoppingCart, zip, pathname, search);
    let appendParams = '';
    if (urlParams) {
      urlParams.forEach((value: string, key: string) => {
        appendParams += `&${key}=${value}`;
      });
    }
    rudderanalytics.track(RudderEvent.FlowEntry, {
      page: pathname,
      method: isLicensePlate ? 'License Plate' : 'VIN',
    });
    // eslint-disable-next-line func-names
    setTimeout(function () {
      window.open(`${FLOW_ENTRY}?${queryParams + appendParams}`, '_self');
    }, 1);
  };

  return (
    <Formik<PlateVinFormFields>
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        license_plate_number: '',
        state: '',
        vin: '',
      }}
      validationSchema={() => getVSchema(isLicensePlate)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setErrors, setValues }) => (
        <Form style={{ width: '100%' }}>
          <Flex
            bg={shoppingCart ? 'transparent' : { base: '#ffffffcc', md: 'white' }}
            border={shoppingCart ? undefined : '1px solid'}
            borderColor={shoppingCart ? undefined : 'transparent'}
            shadow={shoppingCart ? undefined : 'lg'}
            mx="auto"
            maxW="370px"
            // minW={{ base: '90vw', md: '200px' }}
            rounded="10px"
            p="4"
            flexDirection="column"
          >
            <Flex alignItems="center" flexDirection={{ sm: 'column', md: 'row' }}>
              <Text
                noOfLines={2}
                mr={{ md: '3' }}
                textAlign={{ base: 'center', md: 'left' }}
                mt={1}
                mb={{ sm: '2', md: '0' }}
              >
                GET STARTED WITH YOUR {isLicensePlate ? 'LICENSE PLATE' : `VEHICLE'S VIN`}
              </Text>
              <Flex h="40px" ml={{ md: '1' }} minW="170px">
                <Switch
                  firstOption={LICENSE_PLATE_NAME}
                  secondOption={VIN_NAME}
                  onChange={(val) => {
                    handleOnSwitchChange(setCurrentInput, setErrors, setValues, val);
                    handleWarningsReset();
                  }}
                />
              </Flex>
            </Flex>
            <Flex mt="4" mb="2" minH="50px">
              {isLicensePlate ? (
                <>
                  <Input
                    name="license_plate_number"
                    placeholder="License Plate"
                    pr="5px"
                    autoFocus={autoFocus}
                    textTransform="uppercase"
                    onChange={handleWarningsReset}
                    invalid={dealExists || showOldCarWarning}
                  />
                  <Flex maxW={{ sm: '110px', md: '100%' }}>
                    <Select
                      name="state"
                      placeholder="State"
                      options={statesArray}
                      onChange={handleWarningsReset}
                      invalid={dealExists}
                    />
                  </Flex>
                </>
              ) : (
                <Input
                  name="vin"
                  placeholder="VIN"
                  textTransform="uppercase"
                  onChange={handleWarningsReset}
                  invalid={dealExists || showOldCarWarning}
                />
              )}
            </Flex>
            {showOldCarWarning && (
              <Text
                as="span"
                color="red.500"
                fontWeight="semibold"
                textAlign="center"
                fontSize="12px"
              >
                We are typically unable to secure financing for leases that are {DEFAULT_YEAR_DIFF}+
                years old. Thank you.
              </Text>
            )}
            {!dealExists && !showOldCarWarning && (
              <Flex direction="column" alignItems="center">
                <Button type="submit" w="100%" py="20px" fontWeight="600" isLoading={isSubmitting}>
                  {pathname === KWLP_LEASE_BUYOUT
                    ? 'BUYOUT MY LEASE EARLY'
                    : pathname === KWLP_LEASE_BUYOUT_RATES
                    ? 'FIND MY RATE'
                    : pathname === KWLP_LEASE_BUYOUT_SELL
                    ? 'UNLOCK MY EQUITY'
                    : 'FIND MY LOAN'}
                </Button>
              </Flex>
            )}
            {!isAuthenticated && (
              <Subtitle
                textAlign="center"
                lineHeight="25px"
                fontSize="14px"
                maxW={{ base: '350px', lg: '750px' }}
                margin="10px"
                fontWeight="bold"
                display={{
                  base: 'none',
                  md: 'block',
                }}
              >
                Already have an account?{' '}
                <Link
                  to={ACCOUNT_LOGIN}
                  color="royalBlue"
                  fontSize="14px"
                  letterSpacing={0}
                  fontWeight="medium"
                >
                  Login now
                </Link>
              </Subtitle>
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default LicensePlateOrVinInput;
