/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable react/destructuring-assignment */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Flex } from '@chakra-ui/react';
import { PortableTextComponents } from '@portabletext/react';
import { useAtomValue } from 'jotai';
import { ReactNode } from 'react';

import { LandingPageDrill, LandingPageStateContexts } from '../../constants/atom';
import AuthorImageComponent from './Author/AuthorImageComponent';
import BlogImageComponent from './Blog/BlogImageComponent';

const HandleMarks = (marks: string[] | null) => {
  let styles = '';
  if (!marks) {
    return styles;
  }
  styles += marks.includes('strong') ? 'le-bold ' : '';
  styles += marks.includes('underline') ? 'le-underline ' : '';
  styles += marks.includes('em') ? 'le-emphasis ' : '';
  styles += marks.includes('wiki') ? 'le-wiki ' : '';
  styles += marks.includes('phonenumber') ? 'le-phonenumber ' : '';
  return styles;
};

const RenderMarks = (child: { _type: string; text: string; marks: string[] }) => {
  const inline = HandleMarks(child.marks);
  const v: ReactNode[] = [];
  if (child.marks.includes('phonenumber')) {
    v.push(
      <a href={`tel://${child.text}`} className={inline}>
        {child.text}
      </a>,
    );
  } else if (child.marks.includes('wiki')) {
    v.push(<a className={inline}>{child.text}</a>);
  } else {
    v.push(<span className={inline}>{child.text}</span>);
  }
  return v;
};

const BaseBlock = (props: any) => {
  const { children } = props?.value;
  if (!children || !children.length) {
    return null;
  }
  const v: ReactNode[] = [];
  // eslint-disable-next-line array-callback-return
  children.map((child: { _type: string; text: string; marks: string[] }) => {
    v.push(RenderMarks(child));
  });
  switch (props.value.style) {
    case 'h2':
      return <h2>{v}</h2>;
    default:
      return <p>{v}</p>;
  }
};

const BlogTableRow = ({ row, i }: { row?: any; i: any }) => {
  return (
    <tr>
      {row.cells.map((cell: any) => (
        <td className={i === 0 ? 'le-blog-table-hdr' : ''}>{cell}</td>
      ))}
    </tr>
  );
};

const BlogTable = ({ value }: { value: any }) => {
  return (
    <table className="le-blog-table">
      {value.rows.map((row: any, i: any) => (
        <BlogTableRow row={row} i={i} />
      ))}
    </table>
  );
};

const BlogUL = (children: any) => {
  return (
    <>
      <ul className="le-ul" style={{ margin: '0 24px 16px' }}>
        {children}
      </ul>
    </>
  );
};

// TODO: need Wiki support in list items
const BlogLI = (children: any, marks?: string[] | null) => {
  const classes: string = marks ? HandleMarks(marks) : '';
  return (
    <>
      <li
        className={classes}
        style={{
          padding: '0 8px 8px 0',
        }}
      >
        {children}
      </li>
    </>
  );
};

const LandingPageBlock = (props: any) => {
  const drill = useAtomValue(LandingPageDrill);
  const states = useAtomValue(LandingPageStateContexts);
  // eslint-disable-next-line react/destructuring-assignment
  const { children } = props?.value;
  if (!children || !children.length) {
    return null;
  }
  let v = '';
  // eslint-disable-next-line array-callback-return
  children.map((child: { _type: string; text: string }) => {
    // eslint-disable-next-line security/detect-unsafe-regex
    const m: RegExpMatchArray | null = child.text.match(/(?<=\{\{).*?(?=\}\})/gm);
    v += child.text;
    if (m) {
      if (m[0] === 'state' && drill && states.includes(drill)) {
        v = v.replace(`{{${m[0]}}}`, drill);
      }
    }
  });
  return <p>{v}</p>;
};

export interface SanityPageProps {
  heading: string;
  subtitle: string;
  widget: boolean;
  body: [];
}

export interface FAQProps {
  question: string;
  answer: [];
}

export interface FAQCategoryProps {
  title: string;
}

export interface CategoryProps {
  title: string;
  nav: boolean;
}

export interface LEAssetProps {
  name: string;
  url: string;
  description: string;
  publishDate: string;
}

export interface LEAssetFolderProps {
  title: string;
}

export interface BlogPostProps {
  description: string;
  browserTitle: string;
  keywords: string;
  title: string;
  slug: {
    current: string;
  };
  publishDate: string;
  updateDate: string;
  authorName: string;
  authorBio: [];
  authorSlug: string;
  authorImage: string;
  categories: string[];
  body: [];
  mainImage?: string;
  snippet?: string;
  widget?: string;
}

export interface PressReleaseProps {
  title: string;
  order: number;
  subtitle: string;
  publishDate: string;
  updateDate: string;
  publishLocation: string;
  published: boolean;
  slug: {
    current: string;
  };
  body: [];
  mainImage?: string;
}

export interface CompanyNewsProps {
  title: string;
  order: number;
  url: string;
  published: boolean;
  mainImage?: string;
}

export interface FeaturedPostProps {
  title: string;
  slug: {
    current: string;
  };
  publishDate: string;
  updateDate: string;
  authorName: string;
  authorSlug: string;
  authorImage: string;
  snippet: string;
  categories: string[];
  mainImage?: string;
}

export interface BlogPostSnippetProps {
  post: {
    title: string;
    slug: {
      current: string;
    };
    publishDate: string;
    updateDate: string;
    authorName: string;
    authorSlug: string;
    authorImage: string;
    snippet: string;
    categories: string[];
    mainImage?: string;
    featured?: number;
  };
}

export interface AuthorProps {
  authorSlug: string;
  authorName: string;
  authorImage: string;
  authorBio: [];
  posts: [];
}

export const ptBaseComponents = {
  block: (props: unknown) => BaseBlock(props),
  types: {
    image: BlogImageComponent,
  },
};

export const SectionJumpTop = () => {
  return (
    <>
      <a
        href="#leTop"
        style={{
          color: 'rgba(8,125,197,1)',
          fontSize: '13px',
        }}
      >
        Top
      </a>
    </>
  );
};

const grabSectionId = (value: any) => {
  let id;
  if (value && value.length) {
    value.map((c: any) => {
      if (c.marks && c.marks.includes('section')) {
        id = c.text;
      }
      return null;
    });
  }
  return id;
};

export const ptBlogComponents = {
  block: {
    normal: (props: any) => {
      return <p className="le-para">{props.children}</p>;
    },
    h1: (props: any) => {
      const id = grabSectionId(props.value.children);
      return (
        <>
          <h1 id={id} className="le-h1" style={{ marginBottom: id ? 0 : '1rem' }}>
            {props.children}
          </h1>
          {id && <SectionJumpTop />}
        </>
      );
    },
    h2: (props: any) => {
      const id = grabSectionId(props.value.children);
      return (
        <>
          <h2 id={id} className="le-h2" style={{ marginBottom: id ? 0 : '1rem' }}>
            {props.children}
          </h2>
          {id && <SectionJumpTop />}
        </>
      );
    },
    h3: (props: any) => {
      const id = grabSectionId(props.value.children);
      return (
        <>
          <h3 id={id} className="le-h3" style={{ marginBottom: id ? 0 : '1rem' }}>
            {props.children}
          </h3>
          {id && <SectionJumpTop />}
        </>
      );
    },
    h4: (props: any) => {
      const id = grabSectionId(props.value.children);
      return (
        <>
          <h4 id={id} className="le-h4" style={{ marginBottom: id ? 0 : '1rem' }}>
            {props.children}
          </h4>
          {id && <SectionJumpTop />}
        </>
      );
    },
    h5: (props: any) => {
      const id = grabSectionId(props.value.children);
      return (
        <>
          <h5 id={id} className="le-h5" style={{ marginBottom: id ? 0 : '1rem' }}>
            {props.children}
          </h5>
          {id && <SectionJumpTop />}
        </>
      );
    },
    h6: (props: any) => {
      const id = grabSectionId(props.value.children);
      return (
        <>
          <h6 id={id} className="le-h6" style={{ marginBottom: id ? 0 : '1rem' }}>
            {props.children}
          </h6>
          {id && <SectionJumpTop />}
        </>
      );
    },
  },
  list: {
    bullet: (props: any) => BlogUL(props.children),
  },
  listItem: {
    bullet: (props: any) => BlogLI(props.children),
  },
  types: {
    image: BlogImageComponent,
    table: BlogTable,
  },
  marks: {
    wiki: ({ value, children }: { value?: any; children: any }) => {
      return <a className="le-wiki">{children}</a>;
    },
    cta: ({ value, children }: { value?: any; children: any }) => {
      return <Button className="le-cta">{children}</Button>;
    },
    alignleft: ({ value, children }: { value?: any; children: any }) => {
      return (
        <Flex mx="auto" flex={1} justifyContent="flex-start" className="le-alft">
          {children}
        </Flex>
      );
    },
    aligncenter: ({ value, children }: { value?: any; children: any }) => {
      return (
        <Flex mx="auto" flex={1} justifyContent="center" className="le-actr">
          {children}
        </Flex>
      );
    },
    alignright: ({ value, children }: { value?: any; children: any }) => {
      return (
        <Flex mx="auto" flex={1} justifyContent="flex-end" className="le-argt">
          {children}
        </Flex>
      );
    },
    phonenumber: ({ value, children }: { value?: any; children?: any }) => {
      return (
        <a href={`tel://${children}`} className="le-phonenumber">
          {' '}
          {children}
        </a>
      );
    },
    link: ({ value, children }: { value?: any; children: any }) => {
      const target = (value?.href || '').startsWith('http') ? '_blank' : undefined;
      return (
        <a href={value?.href} target={target} className="le-blog-link">
          {children}
        </a>
      );
    },
    section: ({ value, children }: { value?: any; children: any }) => {
      return <span className="le-section">{children}</span>;
    },
  },
};

export const ptLandingPageComponents: PortableTextComponents = {
  types: {
    block: (props: unknown) => LandingPageBlock(props),
  },
};

export const ptAuthorComponents = {
  types: {
    image: AuthorImageComponent,
  },
};

export interface LandingPageProps {
  title: string;
  published: boolean;
  browserTitle: string;
  description: string;
  keywords: string;
  slug: string;
  type: string;
  states: string[];
  body: [];
  publishDate: string;
}

export interface SalesProps {
  name: string;
  title: string;
  email: string;
  phone: string;
  image: string;
  location: string;
  homeTown: string;
  leaseEndLove: string;
  hobbies: string;
  slug: {
    current: string;
  };
  hide?: boolean;
}

export interface JobPostingProps {
  name: string;
  location: string;
  department: string;
  url: string;
}
