/* eslint-disable react/destructuring-assignment */
import { Box, Flex } from '@chakra-ui/react';
import { SanityImageSource, getImageDimensions } from '@sanity/asset-utils';
import urlBuilder from '@sanity/image-url';
import { useEffect, useState } from 'react';

import { BrandStampYellow } from '../../../assets/Images';
import client from '../../../client';

interface BlogImageComponentProps {
  value: SanityImageSource & { alt?: string; width?: number; alignment?: string };
  isInline: boolean;
  flavor?: string;
  isAuthor?: boolean;
  isFeatured?: boolean;
}

interface BlogImageComponentImageProps {
  value: SanityImageSource & {
    alt?: string;
    width?: number;
    alignment?: string;
    branding?: boolean;
  };
  isInline: boolean;
  flavor?: string;
  isAuthor?: boolean;
  isFeatured?: boolean;
}

export const BlogImageComponentImage = (props: BlogImageComponentImageProps) => {
  // eslint-disable-next-line react/destructuring-assignment
  const original: {
    width: number;
    height: number;
  } = getImageDimensions(props.value);
  const AUTHOR_DIMENSION = 32;
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const size = () => {
    setIsPortrait(original.height > original.width);
    if (props.isAuthor) {
      setWidth(AUTHOR_DIMENSION);
      setHeight(AUTHOR_DIMENSION);
      return;
    }
    const max_dimension: number = props.isInline
      ? props.flavor && props.flavor === 'snippet'
        ? 300
        : 44
      : isPortrait
      ? original.height
      : original.width;
    if (isPortrait) {
      setHeight(max_dimension);
      setWidth(max_dimension * (original.width / original.height));
    } else {
      setWidth(max_dimension);
      setHeight(max_dimension * (original.height / original.width));
    }
  };
  useEffect(() => {
    if (props.value) {
      size();
    }
  }, [props]);
  return (
    <>
      <Box pos="relative">
        {!props.isAuthor && props.value.branding && (
          <img
            src={BrandStampYellow}
            width={width > 400 ? '72px' : '32px'}
            alt="Lease End"
            style={{
              position: 'absolute',
              left: '4px',
              bottom: '4px',
              borderBottomLeftRadius: '4px',
            }}
          />
        )}
        <img
          src={urlBuilder(client)
            .image(props.value)
            .width(Math.round(width))
            .height(Math.round(height))
            .url()}
          alt={props.value.alt || 'Lease End'}
          loading="lazy"
          style={{
            display: props.isInline ? 'inline-block' : 'block',
            aspectRatio: width / height,
            width: props.isFeatured
              ? `100%`
              : props.value.width && props.value.width > 0
              ? `${props.value.width}%`
              : `${width}px`,
            borderRadius: 8,
            padding: props.flavor && props.flavor === 'snippet' ? 4 : 0,
            border: props.flavor && props.flavor === 'snippet' ? '1px solid #aaa' : '',
          }}
        />
      </Box>
    </>
  );
};

const BlogImageComponent = ({ ...props }: BlogImageComponentProps) => {
  const i = BlogImageComponentImage(props);
  let a = 'flex-start';
  if (props.value.alignment && props.value.alignment === 'center') {
    a = 'center';
  } else if (props.value.alignment && props.value.alignment === 'right') {
    a = 'flex-end';
  }
  return (
    <>
      {props.isAuthor || props.isInline || props.isFeatured ? (
        <>{i}</>
      ) : (
        <Flex justifyContent={a} w="100%">
          {i}
        </Flex>
      )}
    </>
  );
};

export default BlogImageComponent;
