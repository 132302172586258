/* eslint-disable @typescript-eslint/ban-types */

/* eslint-disable func-names */

/* eslint-disable security/detect-non-literal-fs-filename */
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { MAIN_NUMBER } from '../constants/contact';
import {
  VIPPhoneFetchVipDocument,
  VIPPhoneImportDocument,
  VIPPhoneUpdateDocument,
} from '../gql/vipPhoneGql';
import { VIPDealId, VIPInfo } from '../helpers/Atom';
import useUrlGuid from '../hooks/useUrlGuid';
import Loading from '../pages/Loading';
import Setter from '../pages/VIP/Setter';
import { VIP_PHONE_CONSTANTS, phoneRegex } from '../utils/globals';
import { RudderEvent, rudderanalytics } from '../utils/rudderstack';
import Title from './Title';
import Input from './formComponents/Input';

interface FormProps {
  guid: string | null | undefined;
  setDone: (done: boolean) => unknown;
}

const vipValidationSchema = Yup.object({
  phone: Yup.string().required('Required').matches(phoneRegex, 'Invalid phone.'),
});

const UhOh = () => {
  return (
    <Flex
      bg="white"
      p={8}
      borderRadius={8}
      flex={1}
      boxShadow="0 10px 10px rgba(0,0,0,0.2)"
      flexDir="column"
    >
      <Title color="black" fontSize={28} fontWeight={800}>
        We can't find your vehicle...
      </Title>
      <Text fontSize={16}>
        We're having trouble locating your information - no sweat! Just give us a call anytime and
        our team of VIPs will end your lease in a jiffy!
      </Text>
      <Title color="black" fontSize={24} fontWeight={700}>
        {MAIN_NUMBER}
      </Title>
    </Flex>
  );
};

type FormFields = Yup.InferType<typeof vipValidationSchema>;
interface VIP {
  phone_number: string;
}

const VIPForm = ({ guid, setDone }: FormProps) => {
  const [updateVIPPhone] = useMutation(VIPPhoneUpdateDocument);
  const [importVIPPhone] = useMutation(VIPPhoneImportDocument);
  const [getTempInfo, { loading: tempInfoLoading }] = useLazyQuery(VIPPhoneFetchVipDocument);
  const [vipError, setVipError] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [vipInfo, setVipInfo] = useAtom(VIPInfo);
  const [vipDealId, setVipDealId] = useAtom(VIPDealId);

  const handleTempInfo = async () => {
    try {
      await getTempInfo({
        variables: {
          id: guid,
        },
      }).then((response) => {
        if (!response.data.temporaryInfoVip) {
          setVipError(true);
        } else {
          setVipInfo(response.data.temporaryInfoVip);
          if (response.data.temporaryInfoVip.deal_id) {
            setVipDealId(Number.parseInt(response.data.temporaryInfoVip.deal_id, 10));
          } else {
            setVipDealId(null);
          }
        }
      });
    } catch {
      setVipError(true);
    }
  };

  const handleImport = async () => {
    await importVIPPhone({
      variables: {
        id: guid,
        transformType: VIP_PHONE_CONSTANTS.TransformType,
        importType: VIP_PHONE_CONSTANTS.ImportType,
      },
    })
      .then((response) => {
        if (response.errors) {
          setVipError(true);
          return;
        }
        setDone(true);
        rudderanalytics.track(RudderEvent.VIPConsultation, {
          name: 'VipImport',
          value: guid,
        });
        rudderanalytics.identify({
          deal_id: response.data.temporaryInfoImport.id,
          customer_id: response.data.temporaryInfoImport.customer.id,
        });
        setVipDealId(response.data.temporaryInfoImport.id);
      })
      .catch(() => {
        setVipError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSubmit = async (values: FormFields) => {
    if (!guid) {
      // TODO: proper error hanlding in UI
      // eslint-disable-next-line no-alert
      alert("We're having little issue right now, sorry!");
      return null;
    }
    setSubmitting(true);
    const vipData: VIP = {
      phone_number: values.phone,
    };
    await updateVIPPhone({
      variables: {
        info: {
          id: guid,
          data: vipData,
        },
      },
    })
      .then(() => {
        setVipError(false);
        handleImport();
      })
      .catch(() => {
        setVipError(true);
      });
    return null;
  };

  useEffect(() => {
    if (guid) {
      handleTempInfo();
    }
  }, [guid]);

  return (
    <>
      {tempInfoLoading ? (
        <>
          <Loading />
        </>
      ) : !vipInfo ? (
        <UhOh />
      ) : vipDealId ? (
        <Setter />
      ) : (
        <Formik<FormFields>
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            phone: '',
          }}
          validationSchema={vipValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form style={{ width: '100%' }}>
            <Flex
              bg="#fff"
              border="1px solid"
              borderColor="#98CBE8"
              shadow="lg"
              mx="auto"
              w={{
                base: '100%',
                md: '500px',
              }}
              // minW={{ base: '90vw', md: '200px' }}
              rounded="10px"
              p="4"
              flexDirection="column"
            >
              <Flex alignItems="center" flexDirection={{ sm: 'column', md: 'row' }}>
                <Text
                  bg="#007dc5"
                  w="100%"
                  color="#fff"
                  borderRadius={4}
                  textAlign="center"
                  fontSize={16}
                  fontWeight={600}
                  py={2}
                >
                  {vipInfo ? (
                    <>
                      WE'LL HELP YOU BUY YOUR{' '}
                      <Text as="span" fontWeight={800}>
                        {`${vipInfo.data.year} ${vipInfo.data.model?.toLocaleUpperCase()}`}
                      </Text>{' '}
                      TODAY
                    </>
                  ) : (
                    "WE'LL REACH OUT TO HELP END YOUR LEASE TODAY"
                  )}
                </Text>
              </Flex>
              <Flex mx={0} mt={3} minH="50px">
                {vipError && (
                  <Flex>
                    <Text>Sorry, there was an issue</Text>
                  </Flex>
                )}
                <Input
                  mask="(999) 999-9999"
                  name="phone"
                  placeholder="Your phone number"
                  textTransform="uppercase"
                  _container={{
                    bg: '#fff',
                    borderWidth: 1,
                    borderColor: '#98CBE8',
                    borderRadius: 5,
                    p: 1,
                  }}
                  _input={{
                    borderWidth: 0,
                    borderRadius: 0,
                    fontSize: 20,
                    fontWeight: 500,
                    h: 14,
                    letterSpacing: 0,
                    textTransform: 'none',
                  }}
                  isDisabled={submitting}
                />
              </Flex>
              <Flex flex={1} w="100%" gap={4} alignItems="center" mt={3}>
                <Button
                  type="submit"
                  py="28px"
                  flex={1}
                  fontWeight="600"
                  isLoading={submitting}
                  fontSize={16}
                >
                  CALL ME!
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Formik>
      )}
    </>
  );
};

const LicensePlateOrVinInput_VIPConsult = () => {
  const guid: string | null = useUrlGuid();
  const [done, setDone] = useState<boolean>(false);
  const init = async () => {
    if (guid && !done) {
      rudderanalytics.identify({ guid });
    }
    return null;
  };
  useEffect(() => {
    init();
  }, []);
  if (!guid) {
    return <UhOh />;
  }
  return <VIPForm guid={guid} setDone={setDone} />;
};

export default LicensePlateOrVinInput_VIPConsult;
