/* eslint-disable func-names */

/* eslint-disable security/detect-non-literal-fs-filename */
import { Button, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
// eslint-disable-next-line no-restricted-imports
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { statesArray } from '../constants/states';
import { FLOW_ENTRY } from '../constants/urls';
import { CookieKeys, useCookie } from '../hooks/useCookie';
import { RudderEvent, rudderanalytics } from '../utils/rudderstack';
import {
  DEFAULT_YEAR_DIFF,
  LICENSE_PLATE_NAME,
  LicensePlateOrVinInputProps,
  PlateVinFormFields,
  VIN_NAME,
  createQueryParams,
  getVSchema,
  handleOnSwitchChange,
} from './LicensePlateInputs/utils';
import SwitchEndorsement from './SwitchEndorsement';
import Input from './formComponents/Input';
import Select from './formComponents/Select';

interface EndorsementProps extends LicensePlateOrVinInputProps {
  btnLabel?: string;
}

const LicensePlateOrVinInputEndorsement = ({
  autoFocus = false,
  shoppingCart,
  zip,
  btnLabel = 'FIND MY LOAN',
}: EndorsementProps) => {
  const [savedQueryParams] = useCookie<string>(CookieKeys.QUERY_PARAMS);
  const { search, pathname } = useLocation();
  const [currentInput, setCurrentInput] = useState(LICENSE_PLATE_NAME);
  const [dealExists, setDealExists] = useState(false);
  const [showOldCarWarning, setShowOldCarWarning] = useState(false);
  const isLicensePlate = LICENSE_PLATE_NAME === currentInput;

  const handleWarningsReset = () => {
    setDealExists(false);
    setShowOldCarWarning(false);
  };

  const handleSubmit = async (values: PlateVinFormFields) => {
    const urlParams = new URLSearchParams(search || savedQueryParams || '');
    const queryParams = createQueryParams(values, shoppingCart, zip, pathname, search);
    let appendParams = '';
    if (urlParams) {
      urlParams.forEach((value: string, key: string) => {
        appendParams += `&${key}=${value}`;
      });
    }
    rudderanalytics.track(RudderEvent.FlowEntry, {
      page: pathname,
      method: isLicensePlate ? 'License Plate' : 'VIN',
    });
    // eslint-disable-next-line func-names
    setTimeout(function () {
      window.open(`${FLOW_ENTRY}?${queryParams + appendParams}`, '_self');
    }, 1);
  };

  return (
    <Formik<PlateVinFormFields>
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        license_plate_number: '',
        state: '',
        vin: '',
      }}
      validationSchema={() => getVSchema(isLicensePlate)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setErrors, setValues }) => (
        <Form style={{ width: '100%' }}>
          <Flex bg="transparent" mx="auto" flexDirection="column" w="100%">
            <SwitchEndorsement
              firstOption={LICENSE_PLATE_NAME}
              secondOption={VIN_NAME}
              onChange={(val) => {
                handleOnSwitchChange(setCurrentInput, setErrors, setValues, val);
                handleWarningsReset();
              }}
            />
            <Flex mt="0" mb="2" minH="50px" gap={2}>
              {isLicensePlate ? (
                <>
                  <Input
                    name="license_plate_number"
                    placeholder="License Plate"
                    pr="5px"
                    autoFocus={autoFocus}
                    textTransform="uppercase"
                    onChange={handleWarningsReset}
                    invalid={dealExists || showOldCarWarning}
                    _container={{
                      borderWidth: 0,
                    }}
                    _input={{
                      borderWidth: 1,
                      borderColor: '#ddd',
                      borderRadius: 3,
                      h: '60px',
                      fontSize: 20,
                    }}
                  />
                  <Flex w={{ sm: '160px', md: '250px' }}>
                    <Select
                      name="state"
                      placeholder="State"
                      options={statesArray}
                      onChange={handleWarningsReset}
                      invalid={dealExists}
                      _control={{
                        ps: 8,
                        borderWidth: 1,
                        borderColor: '#ddd',
                        h: '60px',
                        fontSize: 20,
                      }}
                    />
                  </Flex>
                </>
              ) : (
                <Input
                  name="vin"
                  placeholder="VIN"
                  textTransform="uppercase"
                  onChange={handleWarningsReset}
                  invalid={dealExists || showOldCarWarning}
                  _container={{
                    borderWidth: 0,
                  }}
                  _input={{
                    borderWidth: 1,
                    borderColor: '#ddd',
                    borderRadius: 3,
                    h: '60px',
                    fontSize: 20,
                  }}
                />
              )}
            </Flex>
            {showOldCarWarning && (
              <Text
                as="span"
                color="red.500"
                fontWeight="semibold"
                textAlign="center"
                fontSize="12px"
              >
                We are typically unable to secure financing for leases that are {DEFAULT_YEAR_DIFF}+
                years old. Thank you.
              </Text>
            )}
            {!dealExists && !showOldCarWarning && (
              <Flex direction="column" alignItems="center" mt={2}>
                <Button
                  type="submit"
                  w="100%"
                  h="42px"
                  fontWeight="600"
                  isLoading={isSubmitting}
                  fontSize={18}
                >
                  {btnLabel}
                </Button>
              </Flex>
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default LicensePlateOrVinInputEndorsement;
