/* eslint-disable no-return-assign */

/* eslint-disable react/jsx-no-undef */

/* eslint-disable react/jsx-pascal-case */
import { Box, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Footer from '../../components/Footer';
import Nav from '../../components/Nav/Nav';
import ContinueContainer from '../../components/ui/GetStarted/ContinueContainer';
import {
  INFLUENCER_CAREDGE,
  INFLUENCER_ERIKA,
  INFLUENCER_JAVUANBANKS,
  INFLUENCER_MOVINGMERCH,
  INFLUENCER_RUSSFLIPSWHIPS,
} from '../../constants/urls';
import useCheckFlowStatus from '../../hooks/useCheckFlowStatus';
import ExpiredPayoffRequestModal from './components/ExpiredPayoffRequestModal';
import FaqAndArticles from './components/FaqAndArticles';
import MidPageBanner from './components/MidPageBanner';
import ReviewLinks from './components/ReviewLinks';
import Reviews from './components/Reviews';
import Steps from './components/Steps';
import CarEdge from './influencer/CarEdge';
import Erika from './influencer/Erika';
import JavuanBanks from './influencer/JavuanBanks';
import MovingMerch from './influencer/MovingMerch';
import RussFlipsWhips from './influencer/RussFlipsWhips';

const Influencer = () => {
  const { pathname } = useLocation();
  const [expiredPayoffRequestModalOpen, setExpiredPayoffRequestModalOpen] = useState(false);
  const { inFlow, enterFlow, canStartOver, startOver } = useCheckFlowStatus();

  return (
    <>
      <Box
        minH="100%"
        bg="linear-gradient(180deg, rgba(186,244,255,1) 0%, rgba(186,244,255,0.5) 5%, rgba(255,255,255,1) 50%, rgba(186,244,255,0.5) 95%, rgba(186,244,255,1) 100%)"
      >
        <Box>
          <Nav />
        </Box>
        {inFlow ? (
          <>
            <Flex mx="auto" mt={16} mb={12} justifyContent="center">
              <ContinueContainer
                enterFlow={enterFlow}
                canStartOver={canStartOver}
                startOver={startOver}
              />
            </Flex>
          </>
        ) : pathname === INFLUENCER_CAREDGE ? (
          <CarEdge />
        ) : pathname === INFLUENCER_ERIKA ? (
          <Erika />
        ) : pathname === INFLUENCER_RUSSFLIPSWHIPS ? (
          <RussFlipsWhips />
        ) : pathname === INFLUENCER_JAVUANBANKS ? (
          <JavuanBanks />
        ) : pathname === INFLUENCER_MOVINGMERCH ? (
          <MovingMerch />
        ) : null}
        <Steps />
        <ReviewLinks />
        <MidPageBanner />
        <Reviews />
        <FaqAndArticles />
        <Footer bg="matisseBlue" color="white" _socialMediaIcon={{ color: 'white' }} />
      </Box>
      <ExpiredPayoffRequestModal
        isOpen={expiredPayoffRequestModalOpen}
        onClose={() => setExpiredPayoffRequestModalOpen(false)}
      />
    </>
  );
};

export default Influencer;
