import { BoxProps } from '@chakra-ui/react';
import { FormikContextType } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';

import { INVALID_VIN_MSG } from '../../constants/vin';
import { TemporaryShoppingCartDataInput } from '../../gql/generated/graphql';
import { getLandingPagePhoneNumbers } from '../../hooks/useSearchPhoneNumbers';
import { NonNullRequired } from '../../utils/types/typeHelpers';
import validateLicenseNumber, {
  INVALID_LICENSE_PLATE_MSG,
} from '../../utils/validation/licenseNumberValidator';
import validateVin from '../../utils/validation/vinValidator';

export const licensePlateValidationSchema = Yup.object({
  license_plate_number: Yup.string()
    .required('Required field')
    .test('is-valid-license-number', INVALID_LICENSE_PLATE_MSG, async (value) =>
      validateLicenseNumber({ licenseNumber: value }),
    ),
  state: Yup.string().required('Required field'),
});

export const vinValidationSchema = Yup.object({
  vin: Yup.string()
    .required('Required field')
    .test('is-valid-vin', INVALID_VIN_MSG, (value) => validateVin(value)),
});

export const getVSchema = (isLicensePlate: boolean) =>
  isLicensePlate ? licensePlateValidationSchema : vinValidationSchema;

export const FLOW_ENTRY_PARAMS = {
  ZIP: 'zip',
  VIN: 'vin',
  LICENSE_PLATE: 'license_plate',
  STATE: 'state',
  CONTACT_US: 'contact_us',
};

export const SHOPPING_CART_PARAMS = {
  PAYOFF: 'payoff',
  CREDIT_SCORE: 'credit_score',
  DOWN_PAYMENT: 'down_payment',
  TERM: 'term',
};

export type PlateVinFormFields = Yup.InferType<
  typeof licensePlateValidationSchema & typeof vinValidationSchema
>;

export const createQueryParams = (
  values: PlateVinFormFields,
  shoppingCart?: NonNullRequired<TemporaryShoppingCartDataInput>,
  zip?: string,
  pathname?: string,
  search?: string,
) => {
  const { vin, license_plate_number, state } = values;
  const { buyout, credit_score, down_payment, loan_term } = shoppingCart || {};

  getLandingPagePhoneNumbers(pathname, search);

  const params = {
    [FLOW_ENTRY_PARAMS.LICENSE_PLATE]: license_plate_number,
    [FLOW_ENTRY_PARAMS.STATE]: state,
    [FLOW_ENTRY_PARAMS.VIN]: vin,
    [FLOW_ENTRY_PARAMS.ZIP]: zip,
    // [FLOW_ENTRY_PARAMS.CONTACT_US]: phone_number,
    [SHOPPING_CART_PARAMS.PAYOFF]: buyout,
    [SHOPPING_CART_PARAMS.CREDIT_SCORE]: credit_score,
    [SHOPPING_CART_PARAMS.DOWN_PAYMENT]: down_payment,
    [SHOPPING_CART_PARAMS.TERM]: loan_term,
  };

  const cleanedParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value === undefined) return acc;
    return { ...acc, [key]: value.toString() };
  }, {});

  return new URLSearchParams(cleanedParams).toString();
};

export const LICENSE_PLATE_NAME = 'PLATE';
export const VIN_NAME = 'VIN';
export const DEFAULT_YEAR_DIFF = 8;

export interface LicensePlateOrVinInputProps extends BoxProps {
  autoFocus?: boolean;
  shoppingCart?: NonNullRequired<TemporaryShoppingCartDataInput>;
  zip?: string;
}

type FormikContext = FormikContextType<PlateVinFormFields>;
type setErrorsType = FormikContext['setErrors'];
type setValuesType = FormikContext['setValues'];

export const handleOnSwitchChange = (
  setCurrentInput: Dispatch<SetStateAction<string>>,
  setErrors: setErrorsType,
  setValues: setValuesType,
  newValue: string,
): void => {
  setCurrentInput(newValue);
  setValues({ vin: '', license_plate_number: '', state: '' });
  setErrors({});
};
