import { useLocation } from 'react-router-dom';

import { FLOW_ENTRY_PARAMS } from '../components/LicensePlateInputs/utils';
import { MAIN_NUMBER } from '../constants/contact';
import { LDFlags } from '../constants/experiments';
import {
  BEST_MONEY,
  MKTG_BING_BRANDED_SEARCH,
  MKTG_BING_LEASE_BUYOUT,
  MKTG_BING_LEASE_BUYOUT_LOANS,
  MKTG_BING_LEASE_BUYOUT_RATES,
  MKTG_DEMAND_GEN,
  MKTG_EMAIL,
  MKTG_FACEBOOK,
  MKTG_INSTAGRAM,
  MKTG_X,
  MKTG_YOUTUBE,
} from '../constants/urls';
import { CookieKeys, getCookie } from './useCookie';
import useFlag from './useFlag';

const PAID_SEARCH = 'paid-search';
const CALL_CAMPAIGN = 'call-campaign';
const GOOGLE_DISPLAY = 'google-display';
const GOOGLE_VIDEO = 'google-video';
const FACEBOOK = 'facebook';
const TIKTOK = 'tiktok';
const PMAX = 'pmax';
const CONSUMER_VOICE = 'consumervoice';
const VERSE = 'verse';
const CJ = 'cj';

export const LANDING_PAGE_PARAMS = {
  SOURCE: 'source',
  UTM_SOURCE: 'utm_source',
};

export const getPhoneNumberHelper = (mktSource: string | null) => {
  if (!mktSource) {
    return MAIN_NUMBER;
  }
  switch (mktSource.toLowerCase()) {
    case FACEBOOK:
      return '(833) 723-0080';
    case GOOGLE_DISPLAY:
      return '(833) 700-9901';
    case GOOGLE_VIDEO:
      return '(833) 965-0600';
    case PAID_SEARCH:
      return '(844) 334-4331';
    case CALL_CAMPAIGN:
      return '(833) 902-2226';
    case TIKTOK:
      return '(844) 670-0660';
    case PMAX:
      return '(833) 963-3366';
    case CONSUMER_VOICE:
      return '(877) 425-0460';
    case VERSE:
      return '(855) 582-0110';
    case BEST_MONEY:
    case CJ:
      return '(833) 988-1699';
    case MKTG_INSTAGRAM:
      return '(844) 968-4047';
    case MKTG_FACEBOOK:
      return '(844) 679-1188';
    case MKTG_EMAIL:
      return '(844) 748-5668';
    case MKTG_YOUTUBE:
      return '(844) 895-4226';
    case MKTG_DEMAND_GEN:
      return '(844) 757-0376';
    case MKTG_X:
      return '(844) 673-3501';
    case MKTG_BING_BRANDED_SEARCH:
      return '(844) 628-9796';
    case MKTG_BING_LEASE_BUYOUT:
    case MKTG_BING_LEASE_BUYOUT_LOANS:
    case MKTG_BING_LEASE_BUYOUT_RATES:
      return '(844) 926-2034';
    default:
      return MAIN_NUMBER;
  }
};

const getPhoneFromParamString = (paramString: string) => {
  const params = new URLSearchParams(paramString);
  // we weren't previously sending "contact_us"
  // so we need to check for "source" and "utm_source" for older users
  const possibleKeys = [
    FLOW_ENTRY_PARAMS.CONTACT_US,
    LANDING_PAGE_PARAMS.SOURCE,
    LANDING_PAGE_PARAMS.UTM_SOURCE,
  ];

  const phones = possibleKeys.map((key) => {
    const savedPhone = params.get(key);
    const isPhoneNumber = key === FLOW_ENTRY_PARAMS.CONTACT_US;
    if (savedPhone) {
      // logic for utm_source & source
      if (!isPhoneNumber) {
        return getPhoneNumberHelper(savedPhone);
      }
      // contact_us is a phone number and does not need processed.
      return savedPhone;
    }
    return null;
  });

  // Find first non-null value in phones array
  const phone = phones.find((p) => !!p);

  return phone || MAIN_NUMBER;
};

export const getLandingPagePhoneNumbers = (path?: string, search?: string) => {
  // Check for Path
  if (path !== '/' && !!path) {
    return getPhoneNumberHelper(path);
  }

  // No Path, Check for URL Params
  if (search) {
    return getPhoneFromParamString(search);
  }

  // No Path, No URL Params, Check for Cookie
  const savedQuery = getCookie<string>(CookieKeys.QUERY_PARAMS) || '';
  if (savedQuery) {
    return getPhoneFromParamString(savedQuery);
  }

  // None of the above, return Main Number
  return MAIN_NUMBER;
};

export const useLandingPagePhoneNumbers = () => {
  const { pathname, search } = useLocation();
  const isLM1334Active = useFlag(LDFlags.LM1334);

  if (isLM1334Active || pathname === BEST_MONEY) {
    return getLandingPagePhoneNumbers(pathname, search);
  }
  return getLandingPagePhoneNumbers('/', search);
};

export default useLandingPagePhoneNumbers;
