/* eslint-disable react/jsx-pascal-case */
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

import PageFor404s from '../404Page';
import {
  CivicGuy,
  DkLpBg,
  GoogleReviews,
  OrangeHeadset,
  SleepyCouch,
  TrustPilotIcon,
  VipConsult,
  VipNoDealer,
} from '../../assets/Images';
import Footer from '../../components/Footer';
import LicensePlateOrVinInput_VIPConsult from '../../components/LicensePlateOrVinInput_VIPConsult';
import LogoLink from '../../components/LogoLink';
import Nav from '../../components/Nav/Nav';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import { LDFlags } from '../../constants/experiments';
import { GOOGLE, TRUSTPILOT } from '../../constants/urls';
import { VIPDealId, VIPHasBeenSet, VIPInfo, VIPSetterProfile } from '../../helpers/Atom';
import { useDevice, useFlag } from '../../hooks';
import useCustomBreakpointCallback from '../../hooks/useCustomBreakpointCallback';
import { DevicesEnum } from '../../hooks/useDevice';
import useLeaseEndHours from '../../hooks/useLeaseEndHours';
import { convertToLeaseEndTimeZone } from '../../utils/dates';
import { LE_DOW_OFF, LE_HOUR_START, fetchMarketingDeals } from '../../utils/globals';
import ImageComponent from '../Sanity/ImageComponent';

const VIPGet = ({ image, title, body }: { image: string; title: string; body: string }) => {
  return (
    <Flex
      flex={1}
      bg="#f3f3f3"
      borderRadius={8}
      p={4}
      alignItems="flex-start"
      gap={4}
      mx={{
        base: 4,
        md: 0,
      }}
    >
      <Image src={image} h="78px" />
      <Flex flexDir="column">
        <Subtitle
          color="black"
          textTransform="uppercase"
          fontSize={18}
          fontWeight={800}
          m="0 !important"
          p={0}
        >
          {title}
        </Subtitle>
        <Text fontSize={16}>{body}</Text>
      </Flex>
    </Flex>
  );
};
const LP = () => {
  const bpv = useCustomBreakpointCallback();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const isMobile = useDevice() === DevicesEnum.mobile;
  const isClosed = useLeaseEndHours();
  const LM1235 = useFlag(LDFlags.LM1235);
  const [vipDealId] = useAtom(VIPDealId);
  const [vipSetter] = useAtom(VIPSetterProfile);
  const [, setVipHasBeenSet] = useAtom(VIPHasBeenSet);
  const [vipInfo] = useAtom(VIPInfo);
  const [, setTomorrowLabel] = useState<string | null>(null);
  const [tomorrowNextLabel, setTomorrowNextLabel] = useState<string | null>(null);
  const [isClosedTomorrow, setIsClosedTomorrow] = useState<boolean>(false);
  const [, setIsClosedTomorrowNext] = useState<boolean>(false);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const init = () => {
    const tmrw = moment(convertToLeaseEndTimeZone(moment(new Date()).format('YYYY-MM-DD'))).add(
      1,
      'day',
    );
    const tmrwDow = tmrw.format('ddd').toLocaleUpperCase();
    setIsClosedTomorrow(LE_DOW_OFF.includes(tmrwDow));
    setTomorrowLabel(`${tmrw.format('dddd')} the ${tmrw.format('Do')}`);
    const tmrwNext = moment(convertToLeaseEndTimeZone(moment(new Date()).format('YYYY-MM-DD'))).add(
      2,
      'days',
    );
    const tmrwNextDow = tmrwNext.format('ddd').toLocaleUpperCase();
    setIsClosedTomorrowNext(LE_DOW_OFF.includes(tmrwNextDow));
    setTomorrowNextLabel(`${tmrwNext.format('dddd')} the ${tmrwNext.format('Do')}`);
  };
  useEffect(() => {
    setVipHasBeenSet(!!vipSetter);
    return setShowConfetti(!!vipSetter);
  }, [vipSetter]);
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {showConfetti && (
        <Flex pos="absolute" left={0} right={0} justifyContent="center">
          <ConfettiExplosion />
        </Flex>
      )}
      <Nav />
      {!LM1235 ? (
        <PageFor404s />
      ) : (
        <>
          <Flex
            justifyContent={{
              base: 'center',
              md: 'space-between',
            }}
            flex={1}
            mx="auto"
            mb={2}
            py={16}
            px={{
              base: 4,
              md: 16,
            }}
            bgImage={DkLpBg}
            backgroundPosition="bottom center"
            backgroundSize="cover"
            pb={vipSetter ? 120 : 200}
            flexDir="column"
            maxW="1820px"
          >
            {!vipSetter && (
              <Flex
                flexDir="row"
                gap={0}
                justifyContent={{
                  base: 'center',
                  md: 'space-between',
                }}
                flexWrap={{
                  base: 'wrap',
                  md: 'wrap',
                }}
                display={{
                  base: 'flex',
                }}
              >
                <Title
                  textAlign={{
                    base: 'center',
                    md: 'left',
                  }}
                  fontSize={16}
                  fontWeight={600}
                  color="#FFF"
                  m={0}
                >
                  {vipDealId ? <>HANG TIGHT</> : <>WELCOME BACK</>}
                  {(vipInfo &&
                    vipInfo?.data.firstName &&
                    ` ${`${vipInfo?.data.firstName?.toLocaleUpperCase()}`}`) ||
                    ''}
                  !
                </Title>
              </Flex>
            )}
            <Flex
              flexDir={{ base: 'column', lg: 'row' }}
              gap={0}
              justifyContent={{ base: 'center', lg: 'space-between' }}
              mt={2}
            >
              <Flex flexDir="column" gap={0}>
                <Title
                  textAlign={{ base: 'center', md: 'left' }}
                  fontSize={{
                    base: 42,
                    md: '3.25rem',
                  }}
                  fontWeight={800}
                  color="#f6b528"
                  m={0}
                  lineHeight={{
                    base: 1.2,
                    md: 1.3,
                  }}
                  mb={4}
                >
                  {vipSetter ? (
                    <Flex
                      gap={8}
                      w="100%"
                      justifyContent="center"
                      flexDir={{
                        base: 'column',
                        md: 'row',
                      }}
                      alignItems={{
                        base: 'center',
                        md: 'flex-start',
                      }}
                    >
                      <ImageComponent value={vipSetter.image} isInline sync width={230} />
                      <Flex flexDir="column">
                        <Box>
                          <>Meet {vipSetter.name}</>
                        </Box>
                        <Title
                          textAlign={{ base: 'center', lg: 'left' }}
                          fontSize={{
                            base: 24,
                            md: 32,
                          }}
                          fontWeight={700}
                          color="white"
                          m={0}
                          mt={{
                            base: 8,
                            md: 2,
                          }}
                        >
                          {isClosed ? (
                            <>
                              {vipSetter.name.split(' ')[0]} will ring you{' '}
                              {isClosedTomorrow ? tomorrowNextLabel : 'tomorrow'}!
                              {vipSetter && isClosed && (
                                <>
                                  <Text
                                    fontWeight={500}
                                    pt={1}
                                    ps={1}
                                    as="span"
                                    display="block"
                                    fontSize={12}
                                  >
                                    Our consultants are back in action at {LE_HOUR_START}:00am!
                                    (Mountain)
                                  </Text>
                                </>
                              )}
                            </>
                          ) : (
                            <>{vipSetter.name.split(' ')[0]} will ring in 5 minutes.</>
                          )}
                        </Title>
                        <Subtitle
                          color="white"
                          css={{
                            margin: '24px 0 0 !important',
                            padding: '0 !important',
                          }}
                          fontSize={{
                            base: 18,
                            md: 21,
                          }}
                          lineHeight={1.5}
                          fontWeight={400}
                          textAlign={{ base: 'center', md: 'left' }}
                        >
                          In 10 easy minutes you'll end your lease for your {vipInfo?.data.make}. No
                          dealer, no banks, no DMV - just like magic!
                        </Subtitle>
                        {vipSetter.phone && (
                          <Flex flexDir="row" mt={8} gap={2} alignItems="center">
                            <Text fontWeight={600} color="white" fontSize="16">
                              Questions before your call?
                            </Text>
                            <Link
                              color="inherit"
                              fontSize={16}
                              fontWeight={600}
                              _hover={{
                                color: 'inherit',
                                textDecoration: 'underline',
                              }}
                              textDecoration="none"
                              href={`tel:${vipSetter.phone}`}
                            >
                              Send {vipSetter.name.split(' ')[0]} a text message.
                            </Link>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  ) : vipDealId ? (
                    <>We're locating your consultant right now!</>
                  ) : (
                    <>Own Your Leased Car, Hassle-Free</>
                  )}
                </Title>
                {!vipSetter && (
                  <Title
                    textAlign={{ base: 'center', lg: 'left' }}
                    fontSize={{
                      base: 24,
                      md: 32,
                    }}
                    fontWeight={700}
                    color="white"
                    m={0}
                    mt={{
                      base: 8,
                      md: 2,
                    }}
                  >
                    {vipDealId ? (
                      <>Your personal VIP lease expert is moments away...</>
                    ) : (
                      <>Book Your Free 10-Minute Phone Consultation</>
                    )}
                  </Title>
                )}
                {!vipSetter && (
                  <Subtitle
                    color="white"
                    css={{
                      margin: '24px 0 0 !important',
                      padding: '0 !important',
                    }}
                    fontSize={{
                      base: 18,
                      md: 21,
                    }}
                    lineHeight={1.5}
                    fontWeight={400}
                    textAlign={{ base: 'center', lg: 'left' }}
                  >
                    {vipDealId ? (
                      <>Don't close this window, it won't take long.</>
                    ) : (
                      <>
                        {fetchMarketingDeals(true)} drivers have turned their Lease into Ownership
                      </>
                    )}
                  </Subtitle>
                )}
              </Flex>
              {!vipSetter && (
                <Flex
                  w={{
                    base: '100%',
                    md: 700,
                  }}
                  mt={12}
                  mx={{
                    base: 0,
                    md: 16,
                  }}
                  pos="relative"
                >
                  <LicensePlateOrVinInput_VIPConsult />
                </Flex>
              )}
            </Flex>
          </Flex>
          {!vipSetter && (
            <Flex
              maxW="1200px"
              mt={{
                base: '-180px',
                md: '-200px',
              }}
              mb={12}
              justifyContent={{ base: 'center', md: 'space-between' }}
              alignItems="center"
              mx="auto"
              flexDir={{
                base: 'column',
                md: 'row',
              }}
            >
              <Flex
                pos="relative"
                justifyContent={{
                  base: 'center',
                  md: 'flex-start',
                }}
                w={{
                  base: '100%',
                  lg: 'auto',
                }}
              >
                <Image
                  pos="relative"
                  src={VipConsult}
                  w={{
                    base: 350,
                    md: 300,
                    lg: 400,
                  }}
                  zIndex={2}
                />
                <Image
                  src={CivicGuy}
                  w={{
                    base: 330,
                    xl: 400,
                  }}
                  top="165px"
                  right={{
                    base: '50px',
                    lg: '-350px',
                  }}
                  pos="absolute"
                  zIndex={1}
                  opacity={0.95}
                  display={{
                    base: 'none',
                    md: bpv(
                      {
                        600: 'block',
                        1070: 'block',
                      },
                      true,
                    ),
                  }}
                />
              </Flex>
              <Flex
                justifyContent={{
                  base: 'center',
                  md: 'flex-start',
                }}
                mt={{
                  base: 16,
                  md: 24,
                }}
                borderBottomWidth={{
                  base: 1,
                  md: 0,
                }}
                pb={{
                  base: 8,
                  md: 0,
                }}
              >
                <Flex
                  gap={16}
                  flexDir={{
                    base: 'column',
                  }}
                  flex={1}
                >
                  <LogoLink
                    src={TrustPilotIcon}
                    link={TRUSTPILOT}
                    size={{
                      base: '90px',
                    }}
                    alt="Lease End's reviews from Trustpilot."
                    ariaLabel="Lease End's reviews from Trustpilot."
                    m="0 auto"
                  />
                  <LogoLink
                    src={GoogleReviews}
                    link={GOOGLE}
                    size={{
                      base: '90px',
                    }}
                    alt="Lease End's reviews from Google."
                    ariaLabel="Lease End's reviews from Google."
                    m="0 auto"
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex mx="auto" justifyContent="center" flexDir="column" maxW="1200px" mb={16}>
            <Title color="black" fontWeight={800} mb={8}>
              Here's what you'll get with your free consultation
            </Title>
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="center"
              gap={4}
              flexDir={{
                base: 'column',
                md: 'row',
              }}
            >
              <VIPGet
                image={OrangeHeadset}
                title="worry-free"
                body="Our expert advisors will make this the easiest car purchase ever"
              />
              <VIPGet
                image={SleepyCouch}
                title="stress-free"
                body="Answer a few questions and boom...own your car"
              />
              <VIPGet
                image={VipNoDealer}
                title="hassle-free"
                body="We handle it all for you - no dealer, no banks, no DMV!"
              />
            </Flex>
          </Flex>
        </>
      )}
      <Footer />
    </>
  );
};

export default LP;
