/* eslint-disable func-names */

/* eslint-disable security/detect-non-literal-fs-filename */
import { Button, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
// eslint-disable-next-line no-restricted-imports
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { statesArray } from '../constants/states';
import {
  BEST_MONEY,
  FLOW_ENTRY,
  KWLP_LEASE_BUYOUT,
  KWLP_LEASE_BUYOUT_RATES,
  KWLP_LEASE_BUYOUT_SELL,
  REDIRECT_TO_CV,
  REDIRECT_TO_TTCL,
} from '../constants/urls';
import { CookieKeys, useCookie } from '../hooks/useCookie';
import { RudderEvent, rudderanalytics } from '../utils/rudderstack';
import {
  LICENSE_PLATE_NAME,
  LicensePlateOrVinInputProps,
  PlateVinFormFields,
  createQueryParams,
  getVSchema,
  handleOnSwitchChange,
} from './LicensePlateInputs/utils';
import Input from './formComponents/Input';
import Select from './formComponents/Select';
import BestMoneyPartnershipBadge from './ui/GetStarted/BestMoneyPartnershipBadge';
import ConsumerVoicePartnershipBadge from './ui/GetStarted/ConsumerVoicePartnershipBadge';
import TopTenCarLoansPartnerShipBadge from './ui/GetStarted/TopTenCarLoansPartnershipBadge';

const LicensePlateOrVinInput_LPRates = ({
  autoFocus = false,
  shoppingCart,
  zip,
}: LicensePlateOrVinInputProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [savedQueryParams] = useCookie<string>(CookieKeys.QUERY_PARAMS);
  const { search, pathname } = useLocation();
  const bestMoneyBadge = pathname === BEST_MONEY && !isMobile;
  const consumerVoiceBadge = REDIRECT_TO_CV.includes(pathname) && !isMobile;
  const topTenCarLoansBadge = REDIRECT_TO_TTCL.includes(pathname) && !isMobile;
  const [currentInput, setCurrentInput] = useState(LICENSE_PLATE_NAME);
  const [dealExists, setDealExists] = useState(false);
  const isLicensePlate = LICENSE_PLATE_NAME === currentInput;

  const handleWarningsReset = () => {
    setDealExists(false);
  };

  const handleSubmit = async (values: PlateVinFormFields) => {
    const urlParams = new URLSearchParams(search || savedQueryParams || '');
    const queryParams = createQueryParams(values, shoppingCart, zip, pathname, search);
    let appendParams = '';
    if (urlParams) {
      urlParams.forEach((value: string, key: string) => {
        appendParams += `&${key}=${value}`;
      });
    }
    rudderanalytics.track(RudderEvent.FlowEntry, {
      page: pathname,
      method: isLicensePlate ? 'License Plate' : 'VIN',
    });
    // eslint-disable-next-line func-names
    setTimeout(function () {
      window.open(`${FLOW_ENTRY}?${queryParams + appendParams}`, '_self');
    }, 1);
  };

  return (
    <Formik<PlateVinFormFields>
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        license_plate_number: '',
        state: '',
        vin: '',
      }}
      validationSchema={() => getVSchema(isLicensePlate)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setErrors, setValues }) => (
        <Form style={{ width: '100%' }}>
          <Flex
            bg="#fff"
            border={shoppingCart ? undefined : '1px solid'}
            borderColor="#98CBE8"
            shadow={shoppingCart ? undefined : 'lg'}
            mx="auto"
            w={{
              base: '100%',
              md: '500px',
            }}
            // minW={{ base: '90vw', md: '200px' }}
            rounded="10px"
            p="4"
            flexDirection="column"
          >
            <Flex alignItems="center" flexDirection={{ sm: 'column', md: 'row' }}>
              <Text
                bg="#007dc5"
                w="100%"
                color="#fff"
                borderRadius={4}
                textAlign="center"
                fontSize={16}
                fontWeight={700}
                py={2}
              >
                GET STARTED WITH YOUR {isLicensePlate ? 'LICENSE PLATE' : `VEHICLE'S VIN`}
              </Text>
            </Flex>
            <Flex mx={0} mt={3} minH="50px">
              {isLicensePlate ? (
                <>
                  <Flex w="65%">
                    <Input
                      name="license_plate_number"
                      _container={{
                        bg: '#fff',
                        borderWidth: 1,
                        borderColor: '#98CBE8',
                        borderRightWidth: 0,
                        borderRadius: 5,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        py: 1,
                        ps: 1,
                        pe: 0,
                      }}
                      _input={{
                        borderWidth: 0,
                        borderRightWidth: 1,
                        borderColor: '#98CBE8',
                        borderRadius: 0,
                        fontSize: 20,
                        fontWeight: 500,
                        h: 14,
                        letterSpacing: 0,
                        textTransform: 'none',
                      }}
                      placeholder="License Plate"
                      autoFocus={autoFocus}
                      textTransform="uppercase"
                      onChange={handleWarningsReset}
                      invalid={dealExists}
                    />
                  </Flex>
                  <Flex flex={1}>
                    <Select
                      _container={{
                        bg: '#fff',
                        borderWidth: 1,
                        borderLeftWidth: 0,
                        borderColor: '#98CBE8',
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                        py: 1,
                        pe: 1,
                        ps: 0,
                      }}
                      _control={{
                        border: 0,
                        fontSize: 20,
                        fontWeight: 500,
                        height: 14,
                        textTransform: 'none',
                      }}
                      name="state"
                      placeholder="State"
                      options={statesArray}
                      onChange={handleWarningsReset}
                      invalid={dealExists}
                    />
                  </Flex>
                </>
              ) : (
                <Input
                  name="vin"
                  placeholder="Your vehicle's 17-digit VIN"
                  textTransform="uppercase"
                  onChange={handleWarningsReset}
                  invalid={dealExists}
                  _container={{
                    bg: '#fff',
                    borderWidth: 1,
                    borderColor: '#98CBE8',
                    borderRadius: 5,
                    p: 1,
                  }}
                  _input={{
                    borderWidth: 0,
                    borderRadius: 0,
                    fontSize: 20,
                    fontWeight: 500,
                    h: 14,
                    letterSpacing: 0,
                    textTransform: 'none',
                  }}
                />
              )}
            </Flex>
            <Flex flex={1} w="100%" gap={4} alignItems="center" mt={2} ms={2}>
              <Text
                onClick={() =>
                  handleOnSwitchChange(
                    setCurrentInput,
                    setErrors,
                    setValues,
                    isLicensePlate ? 'VIN' : 'PLATE',
                  )
                }
                cursor="pointer"
                fontSize={14}
                fontWeight={600}
                color="oceanBoatBlue"
                textAlign="left"
              >
                {isLicensePlate ? 'Enter VIN' : 'Enter License Plate'}
              </Text>
            </Flex>
            <Flex flex={1} w="100%" gap={4} alignItems="center" mt={3}>
              <Button
                type="submit"
                py="28px"
                flex={1}
                fontWeight="600"
                isLoading={isSubmitting}
                fontSize={16}
              >
                {pathname === KWLP_LEASE_BUYOUT
                  ? 'BUYOUT MY LEASE EARLY'
                  : pathname === KWLP_LEASE_BUYOUT_RATES
                  ? 'FIND MY RATE'
                  : pathname === KWLP_LEASE_BUYOUT_SELL
                  ? 'UNLOCK MY EQUITY'
                  : 'FIND MY LOAN'}
              </Button>
              {bestMoneyBadge && <BestMoneyPartnershipBadge />}
              {consumerVoiceBadge && <ConsumerVoicePartnershipBadge />}
              {topTenCarLoansBadge && <TopTenCarLoansPartnerShipBadge />}
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default LicensePlateOrVinInput_LPRates;
