/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-underscore-dangle */
import groq from 'groq';
import moment from 'moment';

import client from '../../client';
import {
  AuthorProps,
  BlogPostProps,
  CategoryProps,
  CompanyNewsProps,
  FAQCategoryProps,
  FAQProps,
  JobPostingProps,
  LEAssetFolderProps,
  LEAssetProps,
  LandingPageProps,
  PressReleaseProps,
  SalesProps,
  SanityPageProps,
} from './Model';
import { apiResult } from './Monitor';

const PAGINATION_LIMIT = 10;

const handleFetch = async (endpoint: string, query: string, keys?: any) => {
  let data = null;
  let sc = 200;
  let resultLength = 0;
  const startTs = moment(new Date()).unix();
  const params = keys || {};
  try {
    data = await client.fetch(groq`${query}`, { ...params }).catch((error) => {
      sc = error.statusCode;
    });
    resultLength = data.length;
    return data;
  } finally {
    apiResult({
      startTs,
      endpoint,
      statusCode: sc,
      resultLength,
    });
  }
};

export const pageFetch = async (page: string) => {
  const data: SanityPageProps[] | null = await handleFetch(
    'pageFetch',
    `
    *[_type == "page" && title=='${page}']{
        title,
        subtitle,
        heading,
        widget,
        body
      }
  `,
  );
  return data;
};

export const faqFetch = async (category?: string) => {
  const catWhere = category ? ` && '${category}' in categories[]->title` : '';
  const data: FAQProps[] | null = await handleFetch(
    'faqFetch',
    `
      *[_type == "faq"${catWhere}]{
        question,
        answer,
        "publishDate": _updatedAt,
      }
  `,
  );
  return data;
};

export const faqCategoryFetch = async () => {
  const data: FAQCategoryProps[] | null = await handleFetch(
    'faqCategoryFetch',
    `
      *[_type == "faqCategory"]|order(order){
        title,
      }
  `,
  );
  return data;
};

export const blogMenuFetch = async () => {
  const data: CategoryProps[] | null = await handleFetch(
    'blogMenuFetch',
    `
      *[_type == "category" && nav]|order(order){
        title,
      }
  `,
  );
  return data;
};

export const leAssetFetch = async (folder?: string) => {
  const where = folder ? ` && '${folder}' in folders[]->title` : '';
  const data: LEAssetProps[] | null = await handleFetch(
    'leAssetFetch',
    `
      *[_type == "assets"${where}]{
        name, 
        url,
        description,
        "publishDate": _updatedAt,
      }
  `,
  );
  return data;
};

export const leAssetFolderFetch = async () => {
  const data: LEAssetFolderProps[] | null = await handleFetch(
    'leAssetFolderFetch',
    `
      *[_type == "assetFolders"]|order(order){
        title,
      }
  `,
  );
  return data;
};

export const blogFetch = async (category?: string | null) => {
  const _category = category ? ` && '${category}' in categories[]->title` : '';
  const data: [] | null = await handleFetch(
    'blogFetch',
    `
        *[_type == "post" && published${_category}] | order(publishDate desc){
          title,
          "publishDate": publishedAt,
          "updateDate": _updatedAt,
          "authorName": author->name,
          "authorSlug": author->slug.current,
          "authorImage": author->image,
          "categories": categories[]->title,
          snippet,
          widget,
          slug,
          "mainImage": mainImage
        }
    `,
  );
  return data;
};

export const searchBlog = async (term?: string | null) => {
  const data: BlogPostProps[] | null = await handleFetch(
    'searchBlog',
    `
        *[_type == "post" && published && (snippet match '${term}' || title match '${term}' || categories[]->title match '${term}')] | order(publishDate desc){
          title,
          "publishDate": publishedAt,
          "updateDate": _updatedAt,
          "authorName": author->name,
          "authorSlug": author->slug.current,
          "authorImage": author->image,
          "categories": categories[]->title,
          snippet,
          widget,
          body,
          slug,
          "mainImage": mainImage
        }
    `,
  );
  return data;
};

export const postFetch = async (slug: string) => {
  const data: BlogPostProps | null = await handleFetch(
    'postFetch',
    `
          *[_type == "post" && slug.current == $slug][0]{
            title,
            "publishDate": publishedAt,
            "updateDate": _updatedAt,
            "authorName": author->name,
            "authorBio": author->bio,
            "authorSlug": author->slug.current,
            "authorImage": author->image,
            "categories": categories[]->title,
            body,
            description,
            keywords,
            browserTitle,
            widget,
            mainImage
          }
      `,
    { slug },
  );
  return data;
};

export const featuredPostFetch = async () => {
  const data: BlogPostProps[] | null = await handleFetch(
    'featuredPostFetch',
    '*[_type == "post" && homepage] | order(_updatedAt desc){title,"publishDate": publishedAt,"updateDate": _updatedAt, "authorName": author->name,"authorSlug": author->slug.current,"authorImage": author->image,"categories": categories[]->title,snippet,slug,widget,mainImage}',
  );
  return data;
};

export const authorFetch = async (slug: string, flavor?: string) => {
  const limit: string = flavor && flavor === 'recent' ? '[0...3]' : '';
  const data: AuthorProps | null = await handleFetch(
    'authorFetch',
    `
          *[_type == "author" && slug.current == $slug][0]{
            "authorName": name,
            "authorImage": image,
            "authorSlug": slug.current,
            "authorBio": bio,
            "posts": *[_type == "post" && references(^._id) ]${limit}{
              title,
              "slug": slug.current,
              "publishDate": _updatedAt,
            }
          }
      `,
    { slug },
  );
  return data;
};

export const landingPageFetch = async (slug: string) => {
  const data: LandingPageProps | null = await handleFetch(
    'landingPageFetch',
    `
          *[_type == "landingPage" && slug.current == $slug][0]{
            title,
            "publishDate": _updatedAt,
            "states": states[]->state,
            body,
            type,
            description,
            keywords,
            browserTitle,
          }
      `,
    { slug },
  );
  return data;
};

export const salesTeamFetch = async () => {
  const data: [] | null = await handleFetch(
    'salesTeamFetch',
    `
        *[_type == "sales"] | order(name asc){
          name, slug, title, phone, email, location, startDate, homeTown, leaseEndLove, image
        }
    `,
  );
  return data;
};

export const salesFetch = async (slug: string) => {
  const data: SalesProps | null = await handleFetch(
    'salesFetch',
    `
          *[_type == "sales" && slug.current == $slug][0]{
            name, slug, title, phone, email, location, homeTown, hobbies, image, leaseEndLove
          }
      `,
    { slug },
  );
  return data;
};

export const salesFetchByName = async (name: string) => {
  const data: SalesProps | null = await handleFetch(
    'salesFetch',
    `
          *[_type == "sales" && name == '${name}'][0]{
            name, slug, title, phone, email, location, homeTown, hobbies, image, leaseEndLove
          }
      `,
    { name },
  );
  return data;
};

export const jobPostingFetch = async () => {
  const data: JobPostingProps[] | null = await handleFetch(
    'jobPostingFetch',
    `
        *[_type == "jobs"] | order(department asc){
          name, location, department, url
        }
    `,
  );
  return data;
};

export const PRFetch = async (id: string | null, back?: boolean) => {
  const _srt = id === null ? '' : back ? `&& _id < ${id}` : `&& _id ? ${id}`;
  const data: PressReleaseProps[] | null = await handleFetch(
    'PRFetch',
    `
        *[_type == "pressRelease" && published ${_srt}] | order(order asc, updateDate desc) [0...${PAGINATION_LIMIT}]{
          title,
          order,
          subtitle,
          body,
          publishLocation,
          publishDate,
          "updateDate": _updatedAt,
          slug,
          "mainImage": mainImage
        }
    `,
  );
  return data;
};

export const releaseFetch = async (slug: string) => {
  const data: PressReleaseProps | null = await handleFetch(
    'releaseFetch',
    `
          *[_type == "pressRelease" && slug.current == $slug][0]{
            title,
            order,
            subtitle,
            body,
            publishLocation,
            publishDate,
            "updateDate": _updatedAt,
            slug,
            "mainImage": mainImage
          }
      `,
    { slug },
  );
  return data;
};

export const companyNewsFetch = async (id: string | null, back?: boolean) => {
  const _srt = id === null ? '' : back ? `&& _id < ${id}` : `&& _id ? ${id}`;
  const data: CompanyNewsProps[] | null = await handleFetch(
    'companyNewsFetch',
    `
        *[_type == "companyNews" && published ${_srt}] | order(order asc, _id) [0...${PAGINATION_LIMIT}]{
          title,
          url,
          "mainImage": mainImage
        }
    `,
  );
  return data;
};

export const newsFetch = async (slug: string) => {
  const data: CompanyNewsProps | null = await handleFetch(
    'newsFetch',
    `
        *[_type == "companyNews" && slug.current == $slug][0]{
          title,
          url,
          "mainImage": mainImage
        }
    `,
    { slug },
  );
  return data;
};
