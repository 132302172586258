/* eslint-disable func-names */

/* eslint-disable security/detect-non-literal-fs-filename */
import { BoxProps, Button, Flex, Image, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
// eslint-disable-next-line no-restricted-imports
import { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';

import { LeanInPoint } from '../assets/Images';
import { statesArray } from '../constants/states';
import { FLOW_ENTRY } from '../constants/urls';
import { TemporaryShoppingCartDataInput } from '../gql/generated/graphql';
import { CookieKeys, useCookie } from '../hooks/useCookie';
import { RudderEvent, rudderanalytics } from '../utils/rudderstack';
import { NonNullRequired } from '../utils/types/typeHelpers';
import {
  LICENSE_PLATE_NAME,
  PlateVinFormFields,
  createQueryParams,
  getVSchema,
} from './LicensePlateInputs/utils';
import Input from './formComponents/Input';
import Select from './formComponents/Select';

interface LicensePlateOrVinInput_LPRatesProps extends BoxProps {
  autoFocus?: boolean;
  shoppingCart?: NonNullRequired<TemporaryShoppingCartDataInput>;
  zip?: string;
}

const LicensePlateOrVinInput_Learn = ({
  autoFocus = false,
  shoppingCart,
  zip,
}: LicensePlateOrVinInput_LPRatesProps) => {
  const [savedQueryParams] = useCookie<string>(CookieKeys.QUERY_PARAMS);
  const { search, pathname } = useLocation();
  const [currentInput] = useState(LICENSE_PLATE_NAME);
  const [dealExists, setDealExists] = useState(false);
  const isLicensePlate = LICENSE_PLATE_NAME === currentInput;

  const handleWarningsReset = () => {
    setDealExists(false);
  };

  const handleSubmit = async (values: PlateVinFormFields) => {
    const urlParams = new URLSearchParams(search || savedQueryParams || '');
    const queryParams = createQueryParams(values, shoppingCart, zip, pathname, search);
    let appendParams = '';
    if (urlParams) {
      urlParams.forEach((value: string, key: string) => {
        appendParams += `&${key}=${value}`;
      });
    }
    rudderanalytics.track(RudderEvent.FlowEntry, {
      page: pathname,
      method: isLicensePlate ? 'License Plate' : 'VIN',
    });
    // eslint-disable-next-line func-names
    setTimeout(function () {
      window.open(`${FLOW_ENTRY}?${queryParams + appendParams}`, '_self');
    }, 1);
  };

  return (
    <Formik<PlateVinFormFields>
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        license_plate_number: '',
        state: '',
        vin: '',
      }}
      validationSchema={() => getVSchema(isLicensePlate)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form style={{ width: '100%' }}>
          <Flex
            bg="#fff"
            border="1px solid"
            borderColor="#aaa"
            shadow="lg"
            mx="auto"
            w={{
              base: '100%',
            }}
            rounded="5px"
            p={4}
            flexDirection="column"
            pos="relative"
            zIndex={1}
            mt={{
              base: 0,
              md: 4,
            }}
          >
            <Image
              src={LeanInPoint}
              w="72px"
              pos="absolute"
              left="-34px"
              top="-24px"
              zIndex={2}
              display={{
                base: 'none',
                lg: 'block',
              }}
            />
            <Flex alignItems="center" flexDirection={{ sm: 'column', md: 'row' }}>
              <Text
                bg="#fff"
                w="100%"
                color="#002d5d"
                borderRadius={3}
                textAlign="left"
                fontSize={14}
                fontWeight={800}
                ps={{
                  base: 0,
                  lg: 8,
                }}
              >
                GET STARTED RISK-FREE WITH NO OBLIGATION!
              </Text>
            </Flex>
            <Flex mx={0} mt={2}>
              {isLicensePlate ? (
                <>
                  <Flex w="50%">
                    <Input
                      name="license_plate_number"
                      _container={{
                        bg: '#fff',
                        borderWidth: 1,
                        borderColor: '#ccc',
                        borderRightWidth: 0,
                        borderRadius: 5,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        py: 1,
                        ps: 1,
                        pe: 0,
                      }}
                      _input={{
                        borderWidth: 0,
                        borderRightWidth: 1,
                        borderColor: '#ccc',
                        borderRadius: 0,
                        fontSize: 18,
                        fontWeight: 600,
                        letterSpacing: 0,
                        h: '39px',
                        textTransform: 'none',
                      }}
                      placeholder="Plate #"
                      autoFocus={autoFocus}
                      textTransform="uppercase"
                      onChange={handleWarningsReset}
                      invalid={dealExists}
                    />
                  </Flex>
                  <Flex flex={1}>
                    <Select
                      _container={{
                        bg: '#fff',
                        borderWidth: 1,
                        borderLeftWidth: 0,
                        borderColor: '#ccc',
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                        py: 1,
                        pe: 1,
                        ps: 0,
                      }}
                      _control={{
                        border: 0,
                        fontSize: 18,
                        fontWeight: 600,
                        h: '39px',
                        textTransform: 'none',
                      }}
                      name="state"
                      placeholder="State"
                      options={statesArray}
                      onChange={handleWarningsReset}
                      invalid={dealExists}
                    />
                  </Flex>
                  <Flex ms={2}>
                    <Button
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      type="submit"
                      p={0}
                      flex={1}
                      w="60px"
                      h="48px"
                      fontWeight="700"
                      isLoading={isSubmitting}
                      fontSize={14}
                    >
                      <FaArrowRight />
                    </Button>
                  </Flex>
                </>
              ) : (
                <Input
                  name="vin"
                  placeholder="Your vehicle's 17-digit VIN"
                  textTransform="uppercase"
                  onChange={handleWarningsReset}
                  invalid={dealExists}
                  _container={{
                    bg: '#fff',
                    borderWidth: 1,
                    borderColor: '#98CBE8',
                    borderRadius: 5,
                    p: 1,
                  }}
                  _input={{
                    borderWidth: 0,
                    borderRadius: 0,
                    fontSize: 20,
                    fontWeight: 500,
                    h: 14,
                    letterSpacing: 0,
                    textTransform: 'none',
                  }}
                />
              )}
            </Flex>
            <Text
              pt={2}
              borderTopWidth={1}
              mt={3}
              fontWeight={600}
              fontSize={12}
              color="#787878"
              textAlign="left"
            >
              In just 12 minutes you'll receive competitive offers from the most trusted banks to
              end your lease and keep your car.
            </Text>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default LicensePlateOrVinInput_Learn;
