import { atom } from 'jotai';

import { SalesProps } from '../pages/Sanity/Model';

interface VipInfo {
  id: string;
  deal_id: number | string;
  data: {
    firstName?: string;
    make?: string;
    model?: string;
    year?: string;
  };
}

const BlogSearchTerm = atom<string | null>(null);
const BlogSearch = atom<boolean>(false);

const VIPDealId = atom<number | null>(null);
const VIPSetterProfile = atom<SalesProps | null>(null);
const VIPInfo = atom<VipInfo | null>(null);

const VIPHasBeenSet = atom<boolean>(false);

export { BlogSearch, BlogSearchTerm, VIPDealId, VIPHasBeenSet, VIPInfo, VIPSetterProfile };
