import { gql } from '@apollo/client';

export const VIPPhoneUpdateDocument = gql`
  mutation temporaryInfoUpdate($info: temporaryInfoInput) {
    temporaryInfoUpdate(info: $info) {
      id
      data {
        phone_number
      }
    }
  }
`;

export const VIPPhoneImportDocument = gql`
  mutation temporaryInfoImport($id: ID!, $transformType: transformType!, $importType: importType) {
    temporaryInfoImport(id: $id, transformType: $transformType, importType: $importType) {
      id
      customer {
        id
      }
    }
  }
`;

export const VIPPhoneFetchDocument = gql`
  query temporaryInfo($id: ID!) {
    temporaryInfo(id: $id) {
      id
      data {
        vin
        year
        make
        model
        fuel_type
        vehicle_type
        color
        mileage
        vehicle_payoff
        user_entered_total_payoff
        phone_number
        email
        current_accordion_panel
        lienholder
        lienholderLogo
        lienholder_slug
        other_lienholder
        first_name
        middle_name
        last_name
        address_line
        address_line_2
        city
        state
        county
        zip
        taxes
        vehicle_registration_fee
        doc_fee
        has_full_ssn
        previous_address_line
        previous_address_line_2
        previous_city
        previous_state
        previous_zip
        previous_county
        scheduled_call_datetime
        scheduled_call_datetime_tz
        primary_residence_type
        primary_residence_years
        primary_residence_months
        primary_residence_monthly_payment
        has_same_address_as_cobuyer
        secondary_residence_years
        secondary_residence_months
        dob
        employment_status
        employer_name
        job_title
        employer_phone_number
        years_at_job
        months_at_job
        salary
        pay_frequency
        previous_employer_name
        previous_job_title
        previous_employer_phone_number
        previous_years_at_job
        previous_months_at_job
        retail_book_value
        kbb_retail_mileage_adjustment
        kbb_retail_option_adjustment
        book_value
        kbb_lending_mileage_adjustment
        kbb_lending_option_adjustment
        kbb_valuation_date
        kbb_vehicle_name
        kbb_vehicle_id
        kbb_all_options
        kbb_trim_name
        kbb_selected_options
        good_through_date
        credit_score
        term
        rate
        down_payment
        taxes_fees
        payment_estimate_low
        payment_estimate_high
        payoff_includes_sales_tax
        lh_account_number
        include_cobuyer
        cobuyer_first_name
        cobuyer_last_name
        cobuyer_employment_status
        cobuyer_employer_name
        cobuyer_employer_phone_number
        cobuyer_job_title
        cobuyer_years_at_job
        cobuyer_months_at_job
        cobuyer_salary
        cobuyer_pay_frequency
        cobuyer_previous_employer_name
        cobuyer_previous_employer_phone_number
        cobuyer_previous_job_title
        cobuyer_previous_years_at_job
        cobuyer_previous_months_at_job
        cobuyer_phone_number
        cobuyer_email
        cobuyer_dob
        cobuyer_address_line
        cobuyer_address_line_2
        cobuyer_city
        cobuyer_state
        cobuyer_zip
        cobuyer_county
        cobuyer_primary_residence_monthly_payment
        cobuyer_primary_residence_type
        cobuyer_primary_residence_years
        cobuyer_primary_residence_months
        cobuyer_previous_address_line
        cobuyer_previous_address_line_2
        cobuyer_previous_city
        cobuyer_previous_state
        cobuyer_previous_zip
        cobuyer_previous_county
        cobuyer_secondary_residence_years
        cobuyer_secondary_residence_months
        cobuyer_relation_to_buyer
        cobuyer_marital_status
        old_lease_payment
        vehicle_offer
        estimated_dealer_fees
        condition_report
        mmr_value
        license_plate_number
        license_plate_state
        shopping_cart {
          buyout
          credit_score
          down_payment
          loan_term
          payment_estimate_low
          payment_estimate_high
          estimated_fees
        }
        marital_status
        sales_tax_from_payoff
        last_url
        query_params
        manual_entry
        uploaded_files
        prequalification_success
        is_hard_pull
        prequalification_errors
        cobuyer_prequalification_errors
        prequal_id
      }
    }
  }
`;

export const VIPPhoneFetchVipDocument = gql`
  query temporaryInfoVip($id: ID!) {
    temporaryInfoVip(id: $id) {
      id
      deal_id
      data {
        year
        make
        model
        first_name
        last_name
      }
    }
  }
`;

export const VIPPhoneDealSetterDocument = gql`
  query dealSetter($dealId: ID) {
    dealSetter(dealId: $dealId) {
      setter {
        name
      }
    }
  }
`;
